import React from "react"
import { color, fontSize, space } from '../components/constants'
import styled from 'styled-components'
import { graphql , Link } from "gatsby"
import Img from "gatsby-image"
import Image1Overlay from "../components/Image1Overlay"
import Image2Overlay from "../components/Image2Overlay"
import Image3Overlay from "../components/Image3Overlay"
import Image4Overlay from "../components/Image4Overlay"
import Image5Overlay from "../components/image5Overlay"
import Image6Overlay from "../components/image6Overlay"
import Image7Overlay from "../components/image7Overlay"
import Layout from "../components/layout"
import SEO from "../components/seo"

/* Layouts */
const PageLayout = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 36px;
    margin: 0px 36px 72px;

    @media (max-width: 1024px){
        grid-gap: 24px;
        margin: 0px 24px 24px;
    }

    @media (max-width: 640px){
        grid-template-columns: 100% ;
        ${'' /* grid-template-rows: 100%; */}
        grid-gap: 24px;
        margin: 0px 24px 24px;
    }
`

const Section3Group = styled.div`
    grid-column: 1 / span 2;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-flow: row;
    grid-gap: 36px;

    @media (max-width: 1024px){
        grid-gap: 24px;
    }

    @media (max-width: 640px){
        grid-column: 1 / span 1;
        grid-template-columns: 100% ;
    }
`
const Section3TallGroup = styled.div`
    grid-column: 1 / span 2;
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    grid-auto-flow: row;
    grid-gap: 36px;

    @media (max-width: 1024px){
        grid-gap: 24px;
    }

    @media (max-width: 640px){
        grid-column: 1 / span 1;
        grid-template-columns: 100% ;
    }
`

/* Text Group */
const CellTitleGroupDesktop = styled.div`
    width: 75%;
    margin-top: 8px;
    @media (max-width: 640px){
      display: none;
    }

`
const CellTitleGroupAll = styled.div`
    width: 75%;
    margin-top: 8px;
    @media (max-width: 640px){
        display: inline-block;
        width: 100%;
        margin-bottom: 0px;
    }

`

const CellTitleGroupMobile = styled.div`
    display: none;
    @media (max-width: 640px){
        display: inline-block;
        width: 100%;
        margin-top: 8px;
        margin-bottom: 0px;
    }

`
/* Text Styling */
const Title = styled.div`
    color: ${props => props.color ? color[props.color] : color.blue};
    font-size: ${fontSize[3]};
    font-weight: 200;
    ${'' /* margin-top: ${space[2]+ "px"}; */}
    line-height: 0.8;
    word-wrap:normal; /* YESSSSS */
    word-spacing: 100vw; /* forces wrap after every word */
    text-rendering: optimizeLegibility;
    margin-bottom: 0px;

    @media (max-width: 1024px){
        font-size: ${fontSize[2]};
    }

    @media (max-width: 640px){
        word-spacing: normal;
        font-size: ${fontSize[2]};
    }

`
const Text = styled.p`
    margin-top: ${space[4]+ "px"};
    word-wrap:normal; /* YESSSSS */
    color: ${color.gray2};
    margin-bottom: 0px;

    @media (max-width: 1024px){
        font-size: 16px;
    }
`

/* Image styles */
const ImgSingle = styled(Img)`
    border-radius: 24px;
    border: 1px solid ${props => props.border ? color.gray7 : color.black};
`
const ImgSingleHide = styled(Img)`
    border-radius: 24px;
    border: 1px solid ${props => props.border ? color.gray7 : color.black};
    
    @media (max-width: 640px){
      display: none;
    }
`
const ImgDouble = styled(Img)`
    border-radius: 24px;
    grid-column: 1 / span 2;
    @media (max-width: 640px){
        grid-column: 1 / span 1;
    }
`

export default ({ data }) => (
  <Layout>
    <SEO 
      title="John Cross Neumann"
      description="Interactive Product Design based in Portland Ore."
      image="../images/card.jpg"
    />
      <PageLayout>
        <CellTitleGroupDesktop>
            <Title >scalable design systems</Title>
            <Text>Drive brand consistency through structured visual themes. increase development efficiency with reusable component&nbsp;libraries.</Text>
          </CellTitleGroupDesktop>
          <Link to="/project-dat-ds/"><Image1Overlay /></Link>
        <CellTitleGroupMobile>
            <Title >scalable design systems</Title>
            <Text>Drive brand consistency through structured visual themes. increase development efficiency with reusable component&nbsp;libraries.</Text>
          </CellTitleGroupMobile>
        <Section3Group>
          <ImgSingleHide border="true"
                fluid={data.imageTwo.childImageSharp.fluid}
                alt="Color palette and typography of a design system"
                fadeIn={true}
                 />
          <ImgSingle 
                fluid={data.imageThree.childImageSharp.fluid}
                alt="Set of visually styled interface components"
                fadeIn={true}
                 />
          <ImgSingleHide 
                fluid={data.imageFour.childImageSharp.fluid}
                alt="Library of dropdown items in various states"
                fadeIn={true}
                 />
          </Section3Group>
        <CellTitleGroupMobile>
          <Title color={"white"} >customer-centric interfaces</Title>
          <Text>Understanding customer’s goals, desires and context to create recognizable visual patterns, efficient interaction flows and frictionless task journeys.&nbsp;</Text>
          </CellTitleGroupMobile>
        <Link to="/project-sd-wan/"><Image5Overlay /></Link>
        <CellTitleGroupDesktop>
          <Title color={"white"} >customer-centric interfaces</Title>
          <Text>Understanding customer’s goals, desires and context to create recognizable visual patterns, efficient interaction flows and frictionless task journeys.&nbsp;</Text>
          </CellTitleGroupDesktop>
        <Section3TallGroup>
          <ImgSingleHide 
                fluid={data.imageSix.childImageSharp.fluid}
                alt="Mobile new feed interface"
                fadeIn={true}
                 />
          <ImgSingle 
                fluid={data.imageSeven.childImageSharp.fluid}
                alt="Mobile interface for home router"
                fadeIn={true}
                 />
          <ImgSingleHide 
                fluid={data.imageEight.childImageSharp.fluid}
                alt="Mobile navigation interface"
                fadeIn={true}
                 />
          </Section3TallGroup>
        <CellTitleGroupAll>
          <Title color={"red"} >successful digital products</Title>
          <Text>Deeply understanding customer needs. simple solutions at the exact moment of need. Engaging  audiences to bring them&nbsp;onboard.</Text>
          </CellTitleGroupAll>
        <Link to="/project-scroll/"><Image2Overlay /></Link>
        <Link to="/project-simplifi/"><Image3Overlay /></Link>
        <Link to="/project-dat-one/"><Image4Overlay /></Link>
        <Link to="/project-sd-wan/"><Image6Overlay /></Link>
        <Link to="/project-beme/"><Image7Overlay /></Link>
        <ImgSingle 
                fluid={data.image10.childImageSharp.fluid}
                alt="In-vehical climate control interface"
                fadeIn={true}
                 />
        <ImgSingle 
                fluid={data.image9.childImageSharp.fluid}
                alt="Phone on table with travel application"
                fadeIn={true}
                 />
        <ImgDouble 
                fluid={data.image12.childImageSharp.fluid}
                alt="Hand holding tablet with social network application"
                fadeIn={true}
                />
        </PageLayout>
  </Layout>
)

export const query = graphql`
  query {
    imageTwo: file(relativePath: { eq: "home/ds-2.webp" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    imageThree: file(relativePath: { eq: "home/ds-3.webp" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    imageFour: file(relativePath: { eq: "home/ds-5.webp" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageSix: file(relativePath: { eq: "home/ui-t1.webp" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageSeven: file(relativePath: { eq: "home/ui-t2.webp" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageEight: file(relativePath: { eq: "home/ui-t3.webp" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      image9: file(relativePath: { eq: "home/pd-2.webp" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      image10: file(relativePath: { eq: "home/pd-3.webp" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      image11: file(relativePath: { eq: "home/pd-4.webp" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      image12: file(relativePath: { eq: "home/pd-12.webp" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      image14: file(relativePath: { eq: "home/pd-7.webp" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
  }
`

