import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import './header.css'
import BtnHeader from "./Button-Header"


class Header extends React.Component{
  /* this is declaring the variables */
 constructor(props) {
   super(props);
   this.state = {
     hasScrolled: false
   }
 }
/* this is the listener */
 componentDidMount(){
   window.addEventListener('scroll',this.handleScroll)
 }

 /* this is the function */
 handleScroll = (event) => {
   const scrollTop = window.pageYOffset
   if (scrollTop > 50) {
     this.setState({hasScrolled: true})
   } else {
     this.setState({hasScrolled: false})
   }

 }
 render() {
   return (
    <div className={this.state.hasScrolled ? 'HeaderBlock HeaderScrolled' : 'HeaderBlock'}>
      <div className={this.state.hasScrolled ? 'HeaderNameBlock HeaderNameScrolled' : 'HeaderNameBlock'}>
        <Link to="/">
          <div className="NameTitle">John Cross Neumann</div>
        </Link>
        <div className={this.state.hasScrolled ? 'SubHeader SubHeaderScrolled' : 'SubHeader'}>Design leadership, strategy and&nbsp;execution </div>
        </div>
      <div className="HeaderButtonBlock">
        <div className={this.state.hasScrolled ? 'ButtonPad ButtonPadScrolled' : 'ButtonPad'}>
          <Link to="/about/"><BtnHeader color= "blue">about</BtnHeader></Link>
        </div>
        <a href="mailto:john@johncrossneumann.com"><BtnHeader color= "red">contact</BtnHeader></a>
      </div>
      <div className={this.state.hasScrolled ? 'HeaderDivider HeaderDividerScrolled' : 'HeaderDivider'}></div>
    </div>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: `John Cross Neumann`,
}

export default Header
