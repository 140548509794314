import React from 'react';

const IconMap = {
  "add": Add,
  "remove": Remove,
  "rightarrow": RightArrow,
  "leftarrow": LeftArrow,
  "check": Check
}

const Icon = function(props) {
  const { name, color="white", size=16, ...rest } = props;
  const Drawing = IconMap[name] ? IconMap[name] : null;

  return (
    <svg
      width={size}
      height={size}
      {...rest}
      viewBox="0 0 16 16"
    >
      <Drawing color={color} />
    </svg>
  );
}

function Add(props) {
  return (
    <path fill={props.color} d="M9,7 L14,7 L14,9 L9,9 L9,14 L7,14 L7,9 L2,9 L2,7 L7,7 L7,2 L9,2 L9,7 Z"></path>
  )
}

function Remove(props) {
  return (
    <polygon fill={props.color} points="9.41421356 8 12.9497475 11.5355339 11.5355339 12.9497475 8 9.41421356 4.46446609 12.9497475 3.05025253 11.5355339 6.58578644 8 3.05025253 4.46446609 4.46446609 3.05025253 8 6.58578644 11.5355339 3.05025253 12.9497475 4.46446609"></polygon>
  )
}

function RightArrow(props) {
  return (
    <polygon fill={props.color} id="Path" points="7.99943996 0 6.26491854 1.73504 11.3039913 6.77376 0 6.77376 0 9.22624 11.3039913 9.22624 6.26491854 14.2656 7.99943996 16 16 8"></polygon>
  )
}
function LeftArrow(props) {
    return (
      <polygon fill={props.color} id="Path" points="8.00056004 0 9.73508146 1.73504 4.69600872 6.77376 16 6.77376 16 9.22624 4.69600872 9.22624 9.73508146 14.2656 8.00056004 16 0 8"></polygon>
    )
  }
function Check(props) {
  return (
    <path fill={props.color} d="M5.97093463,12.5775363 L5.9669082,12.5815628 L1.72426751,8.33892209 L3.13848108,6.92470853 L6.28886679,10.0750942 L13.363961,3 L14.7781746,4.41421356 L6.29289322,12.8994949 L5.97093463,12.5775363 Z" id="Combined-Shape"></path>
  )
}

export default Icon;