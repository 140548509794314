import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.com/docs/use-static-query/
 * 
 * addubg this text
 */

const Image6 = () => {
  const data = useStaticQuery(graphql`
    query {
      imagePD8: file(relativePath: { eq: "home/pd-11.webp" }) {
        childImageSharp {
            fluid(maxWidth: 1440) {
                ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  if (!data?.imagePD8?.childImageSharp?.fluid) {
    return <div>Picture not found</div>
  }

  return <Img fluid={data.imagePD8.childImageSharp.fluid} />
}

export default Image6
