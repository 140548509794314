import React from 'react'
import styled from 'styled-components'
import { color, space, fontSize } from './constants';
import Icon from './Icons';
import Image5 from "./image5"

const ImageGroup = styled.div`
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
    cursor: pointer;

    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;

    @media (max-width: 1024px){
    }
    @media (max-width: 640px){
        grid-column-start: 1;
        ${'' /* grid-row-start: 1; */}
        justify-self: stretch;
    }
`

const Image = styled.div`
    grid-column: 1;
    grid-row: 1;
    opacity: 1;
    overflow: hidden;
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
    border-radius: 24px;
    border: 1px solid ${props => props.border ? color.gray7 : color.black};
    &.hover {
    opacity: 0.90;
    transform: scale(1.02, 1.02);
  }
`

const OverlayGroup = styled.div`
    position: relative;
    grid-column: 1;
    grid-row: 1;
    align-self: flex-end;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
    padding: 36px;
    @media (max-width: 1024px){
      padding: 24px;
          }
    @media (max-width: 1024px){
      padding: 24px 24px;
          }
`

const OverlayArrow = styled.div`
    opacity: 0;
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
    &.hover {
        opacity: 1;
    }
`

const Text = styled.div`
  align-self: flex-end;
    left: 0px;
    right: 0px;
    border: 0.5px solid ${color.gray7};
    border-radius: 100px;
    transition: 0.2s all ease;
    font-size: 24px;
    color: ${color.black};
    text-align: center;
    backdrop-filter: blur(6px);
    padding: 12px 36px;
    
    
    @media (max-width: 1024px){
        font-size: 16px;
        padding: ${space[3] + 'px'} ${space[4] + 'px'};
          }
    
    @media (max-width: 640px){
        font-size: 16px;
        padding: 8px ${space[4] + 'px'} 10px;
        background-color: rgba(255, 255, 255, 0.6);
          }

`


class Image5Overlay extends React.Component{
    /* this is declaring the variables */
   constructor(props) {
     super(props);
     this.state = {
       hover: false
     }
   }
  
   /* this is the function */
   toggleHover = (event) => {
        this.setState({hover: !this.state.hover})
        console.log(this.state.hover)
    }

   render() {
     return (
      <ImageGroup onMouseEnter={this.toggleHover} onMouseLeave={this.toggleHover} position={this.props.position}>
      <Image className={this.state.hover ? 'hover' : ''}><Image5 /></Image>
        <OverlayGroup>
            <Text >Case Study</Text> 
            <OverlayArrow className={this.state.hover ? 'hover' : ''}>
            <Icon name="rightarrow" color={color.gray7} size={72}/>
            </OverlayArrow>
            </OverlayGroup>
        </ImageGroup>
      )
    }
  }

  export default Image5Overlay